import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'

import Footer from '../theme/footer'

// import Header from "./header"
// import Footer from "./footer"
import '../../styles/tailwind.css'
import '../../styles/variables.scss'
import '../../styles/main.css'

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)
  
    return (
      <ThemeProvider>
        <main>{children}</main>
        <Footer />
      </ThemeProvider>       
    )
  }
  
  export default Layout