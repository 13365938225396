import React from 'react'
import { Link } from 'gatsby'
import { Container, Grid, Button } from '@material-ui/core'
import Social from '../theme/social'
import styled from 'styled-components'

import Logo from '../../images/mcreilly-logo.svg'

export default function Footer() {

    const Footer = styled.footer`
      background-color:#000000;
      padding:100px 0;
    `

    let address = "https://www.google.com/maps/dir//McReilly's+Pub,+84+Dunlop+St+E,+Barrie,+ON+L4M+1A4/@44.389458,-79.6893642,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x882aa3f53ecff103:0x8be024773586c93d!2m2!1d-79.6871755!2d44.3894542"
    
    return (
      <>
        <Footer>
          <Container className="text-center white-text">
            <Link to="/">
              <img src={Logo} width="200px" alt="McReilly's Logo" className="mx-auto mb-16" />
            </Link>
            <h2>Ready to get <span className="red-text">eating?</span></h2>
            <p className="my-16"><a href={address} target="_blank">82 Dunlop St E, Barrie, ON L4M 1A4</a><br/>
            <a href="tel:7057219521">(705) 721-9521</a><br/>
            Sunday - Thursday, 11 am - 12 am | <span>Friday &amp; Saturday, 11 am - 2 am</span>
            </p>
            <div>
              <a href={address}  target="_blank" className="inline-flex mx-auto border border-white mb-16">
                <Button className="lowercase primary-button">come in &amp; enjoy a beer</Button>
              </a>
            </div>
            <Social />
          </Container>
        </Footer>
      </>  
    )
}