import React from 'react'
import { BrandFacebook, BrandInstagram } from "tabler-icons-react"

export default function Social() {
    return (
        <div className="social-links flex justify-center">
            <a href="https://www.facebook.com/mcreillyspub/" target="_blank"  aria-label="Facebook">
                <BrandFacebook size={32} strokeWidth={1} color={'#DC2626'} />
            </a>
            <a href="https://www.instagram.com/mcreillyspub/?hl=en" target="_blank" aria-label="Instagram">
                <BrandInstagram size={32} strokeWidth={1} color={'#DC2626'} />
            </a>
        </div>
    )
}
